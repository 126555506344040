import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled, { ThemeContext } from "styled-components";
import { Auth, useAuthContext } from "../../../../../components/Auth";
import {
  ListGridToggleButton,
  PrimaryButtonWithPlusIcon,
  PrimaryCancelButton,
  SecondaryButtonMedium,
  TertiaryTextButtonSmall,
} from "../../../../../components/Buttons/Buttons";
import {
  DeleteIcon,
  DocumentIcon,
  EditIcon,
  InfoIcon,
  LinkIcon,
  LoadingIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { Loading, Table } from "../../../../../components/Table/Table";
import { endpoints } from "../../../../../endpoints";
import type {
  DataMutate,
  IPagination,
  Language,
  WithPagination,
} from "../../../../../types/types";
import { isChipTypeArray } from "../../../../../types/types";
import type {
  Assets,
  GridItemProps,
  PIMProduct,
  SupportedAssetAccessibility,
  SupportedAssetCategoryType,
} from "../../../../../types/types.PIM";
import {
  formatDate,
  isAxiosError,
  TablePlaceholder,
  TablePlaceholderWrapper,
  useStoreState,
  useSupportedLanguages,
} from "../../../../../util/util";
import { EditAssetForm } from "../EditAssetForm";
import { AddAssetsForm } from "./AddAssetsForm";
import { RemoveAssetFromProduct } from "./RemoveAssetFromProduct";

import type { Getter, Row as TableRow } from "@tanstack/react-table";
import type { Moment } from "moment";
import { stringify } from "querystring";
import type { Row as RowType } from "react-table";
import useSWR from "swr";
import type { DecodedValueMap, QueryParamConfigMap } from "use-query-params";
import { ArrayParam, ObjectParam, useQueryParams } from "use-query-params";
import type { ChipType } from "../../../../../components/Chips/Chips";
import type { DateRangeStr } from "../../../../../components/DateFilter/date_filter.utils";
import { get_preselected_date_range } from "../../../../../components/DateFilter/date_filter.utils";
import { DateFilter } from "../../../../../components/DateFilter/DateFilter";
import { FilterBy } from "../../../../../components/FilterBy/FilterBy";
import { chipsToStringArray } from "../../../../../components/Filters/Filters";
import { Row } from "../../../../../components/Layout/Layout";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { TableWithBulkAction } from "../../../../../components/Table/TableWithBulkAction";
import { H3, SmallText } from "../../../../../components/Typography/Typography";
import { WithPermission } from "../../../../../components/WithPermission/WithPermission";
import { Flex, Flex1 } from "../../../../../layout/FormLayout";
import { getCountryOption } from "../../../../../util/location";
import { useCountriesList } from "../../../../../util/Locations";
import { GridAssetView } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetView";
import { GridAssetViewWithBulkAction } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/GridAssetView/GridAssetViewWithBulkAction";
import { getLanguageOptionFromSupportedLanguage } from "../../../../admin/SellerAdmin/PIM/SellarAdminPIMAssets/util/AssetsUtil";
import { BulkActionOnAssets } from "../BulkActionOnAssets/BulkActionOnAssets";
import { EditOrGenerateProductDocument } from "../EditOrGenerateProductDocuments/EditOrGenerateProductDocument";
import { AssetNameIcon, DocPortfolioName } from "../util";
import { Modal } from "../../../../../components/Modal/Modal";
import { RadioButton } from "../../../../../components/RadioButton/RadioButton";
import { match } from "ts-pattern";

const TableContainer = styled.div`
  margin-bottom: 24px;
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
`;

const TableFiltersWrapper = styled(Flex1)`
  border-left: ${({ theme }) => `4px solid ${theme.secondaryBorder}`};
  border-top: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-right: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  padding: 5px 10px;
`;

export const ScrollTableWrapper = styled.div<{
  maxHeight?: string;
  ref?:
    | React.RefObject<HTMLDivElement>
    | ((instance: HTMLDivElement | null) => void)
    | null
    | undefined;
}>`
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "348px")};
  position: relative;
  overflow: auto;
  border: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
  border-left: 0;
  table th {
    border-top: 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    top: 0;
  }
  table {
    border: 0;
  }

  table thead td:last-child {
    border-bottom: 0;
  }
  ${TablePlaceholderWrapper} {
    border-bottom: 0;
    border-right: 0;
  }
  ${Loading} {
    padding: 20px 0;
    border-left: ${({ theme }) => `4px solid ${theme.secondaryBorder}`};
  }
`;

const ScrollGridWrapper = styled.div`
  max-height: 670px;
  position: relative;
  overflow: auto;
  border-bottom: ${({ theme }) => `1px solid ${theme.secondaryBorder}`};
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: 162px;
  margin-bottom: 32px;
  border-radius: 4px;
  padding: 4px;
  border: solid 1px ${({ theme }) => theme.errorColor};
  background-color: ${({ theme }) => theme.destructiveButtonHover};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type TableProduct = {
  id: string | boolean;
  name: string;
  expiration_date: string;
  issue_date: string;
  country: string;
  region: string;
  document_type: string;
  asset_type: string;
  category: {
    id: string;
    name: SupportedAssetCategoryType;
  };
  language: string;
};

type TableData = (TableProduct & {
  info: {
    modified_at: string;
    modified_by: string;
    number_of_products: number;
    renewal_date: string;
  };
  country_region: string;
  visibility: SupportedAssetAccessibility;
  canSelect: boolean;
  rowTooltipMessage?: string | JSX.Element;
} & {
    [Prop in keyof Assets]+?: string | number | boolean | ChipType | object;
  })[];

type DateRange = {
  startDate: Moment | null;
  endDate: Moment | null;
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  column-gap: 8px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

export const DocumentsOrDigitalMediaTable = ({
  product,
  title,
  fetchProductData,
  replaceProductId,
  isOutsideLogin = false,
  isShowTableWithBulkAction = false,
  isShowGridAssetWithBulkAction = false,
}: {
  title: string;
  product: PIMProduct;
  fetchProductData: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  isOutsideLogin?: boolean;
  isShowTableWithBulkAction?: boolean;
  isShowGridAssetWithBulkAction?: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [assetData, setAssetData] = useState<Assets[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [offset, setOffset] = useState<number>(0);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [query, setQuery] = useQueryParams(
    title === "Documents"
      ? {
          document_country: ArrayParam,
          document_region: ArrayParam,
          document_type: ArrayParam,
          document_language: ArrayParam,
          document_category: ArrayParam,
          asset_type: ArrayParam,
          issue_date: ObjectParam,
          expiration_date: ObjectParam,
          renewal_date: ObjectParam,
        }
      : {
          media_country: ArrayParam,
          media_region: ArrayParam,
          media_document_type: ArrayParam,
          asset_type: ArrayParam,
          media_language: ArrayParam,
          media_category: ArrayParam,
          media_issue_date: ObjectParam,
          media_expiration_date: ObjectParam,
          media_renewal_date: ObjectParam,
        }
  );
  const countries = useCountriesList();
  const { tenant_id, is_publishing_product, storefront_id, slug } =
    useStoreState();
  const { hasPermission, roleIsGuest, roleIsSellerAdmin } = useAuthContext();

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { roleIsSomeKindOfSeller } = useContext(Auth);
  const requestLimit = 20;
  const totalLimit = 10000;
  const [showSlideOut, setSlideOut] =
    useState<"add" | "edit" | "delete" | "generate" | false>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [editRowData, setEditRowData] = useState<Assets | null>(null);
  const [editRowVisibility, setEditRowVisibility] =
    useState<SupportedAssetAccessibility | null>(null);
  const [deleteRowData, setDeleteRowData] = useState<Assets | null>(null);
  const [list_grid_toggle, set_list_grid_toggle] =
    useState<"list" | "grid">("list");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { supported_languages } = useSupportedLanguages();

  const product_document_ref = useRef<{ clear_poll_timeout: () => void }>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const closeSlideOut = () => setSlideOut(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showEditConfirm, setShowEditConfirm] = useState<boolean>(false);

  const [tableData, setTableData] = useState<TableProduct[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const productStatus = useMemo(() => product.status, [product.status]);

  const [categoryTypes, setCategoryTypes] = useState<string[]>([]);

  const [assetTypes, setAssetTypes] = useState<string[]>([]);

  const [countryFilters, setCountryFilters] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);

  const [documentTypesFilters, setDocumentTypesFilters] = useState<string[]>(
    []
  );
  const [languagesFilters, setLanguagesFilters] = useState<string[]>([]);

  const [selectedRows, setSelectedRows] = useState<string[] | "all">();
  const [selectedItems, setSelectedItems] = useState<Array<Assets>>([]);

  const assetQueryAsParam = (params: DecodedValueMap<QueryParamConfigMap>) => {
    const result: DecodedValueMap<QueryParamConfigMap> = {};

    Object.keys(params).forEach((key) => {
      const val = params[key];

      if (val !== undefined && val !== null && val !== "") {
        if (key.includes("_category")) {
          result["asset_category"] = val;
        } else if (key.includes("asset_type")) {
          result["asset_type"] = val;
        } else if (key.includes("document_type")) {
          result["document_type"] = val;
        } else {
          result[key.split("_")[1]] = val;
        }
      }
      if (key === "issue_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["issue_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["issue_date_end"] = endDate;
        }
      }
      if (key === "media_issue_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["issue_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["issue_date_end"] = endDate;
        }
      }

      if (key === "expiration_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["expiration_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["expiration_date_end"] = endDate;
        }
      }
      if (key === "media_expiration_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["expiration_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["expiration_date_end"] = endDate;
        }
      }
      if (key === "renewal_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["renewal_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["renewal_date_end"] = endDate;
        }
      }
      if (key === "media_renewal_date") {
        const { startDate, endDate } = val ?? {
          startDate: null,
          endDate: null,
        };
        if (startDate && startDate !== "undefined" && startDate !== "null") {
          result["renewal_date_start"] = startDate;
        }
        if (endDate && endDate !== "undefined" && endDate !== "null") {
          result["renewal_date_end"] = endDate;
        }
      }
    });

    return stringify(result) ? `&${stringify(result)}` : ``;
  };

  const {
    data: assets,
    error: assetsError,
    mutate: mutateAssets,
    isValidating: isValidatingAssets,
  } = useSWR<WithPagination<{ data: Assets[] }>, AxiosError>(
    `/v2/tenants/${tenant_id}/pim/products/${product.id}/assets?${
      title === "Documents"
        ? "asset_type=document"
        : title === "Digital Media" && !query.asset_type
        ? "asset_type=image&asset_type=video"
        : ""
    }${assetQueryAsParam(
      query
    )}&limit=${requestLimit}&show_generated_assets=true&offset=${offset}`
  );

  const fetchAssetsForDownload = async () => {
    try {
      const response = await Axios.get(
        `/v2/tenants/${tenant_id}/pim/products/${product.id}/assets?${
          title === "Documents"
            ? "asset_type=document"
            : title === "Digital Media" && !query.asset_type
            ? "asset_type=image&asset_type=video"
            : ""
        }${assetQueryAsParam(
          query
        )}&limit=${totalLimit}&show_generated_assets=true`
      );

      return response.data?.data ?? [];
    } catch (error) {
      notifyError(
        t("An error occurred while fetching assets. Please try again")
      );
    }
  };

  const {
    data: assetFilters,
    mutate: mutateAssetFilters,
    isValidating: isValidatingAssetFilters,
  } = useSWR<
    {
      asset_category: string[];
      asset_type: string[];
      country: string[];
      document_type: string[];
      language: string[];
      region: string[];
    },
    AxiosError
  >(
    `/v2/tenants/${tenant_id}/pim/products/${product.id}/assets/filters?${
      title === "Documents"
        ? "asset_type=document"
        : title === "Digital Media" && !query.asset_type
        ? "asset_type=image&asset_type=video"
        : ""
    }${assetQueryAsParam(query)}${
      activeFilter?.length > 0 ? `&active_filter=${activeFilter}` : ``
    }`,
    {
      onError: () => {
        notifyError(t("could not load asset filters, something went wrong."));
      },
    }
  );

  const [categoryTypesFromURL, setCategoryTypesFromURL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_category
        ? query?.document_category
        : title === "Digital Media" && query?.media_category
        ? query?.media_category
        : []) as string[]
    )
  );

  const [mediaTypesFromURL, setMediaTypesFromURL] = useState(
    getObjArrayFromStringArray((query.asset_type ?? []) as string[])
  );

  const [docTypesFromURL, setDocTypesFromURL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents"
        ? query?.document_type
        : query?.media_document_type) as string[]
    ) || []
  );

  const [countriesFromURL, setCountriesFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_country
        ? query?.document_country?.map(
            (country: string) =>
              countries.find((c) => c.value === country)?.label ?? country
          )
        : title === "Digital Media" && query?.media_country
        ? query?.media_country?.map(
            (country: string) =>
              countries.find((c) => c.value === country)?.label ?? country
          )
        : []) as string[]
    )
  );

  const [regionsFromURL, setRegionsFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_region
        ? query?.document_region
        : title === "Digital Media" && query?.media_region
        ? query?.media_region
        : []) as string[]
    )
  );
  const [languagesFromURL, setLanguagesFromURLL] = useState(
    getObjArrayFromStringArray(
      (title === "Documents" && query?.document_language
        ? query?.document_language
        : title === "Digital Media" && query?.media_language
        ? query?.media_language
        : []) as string[]
    )
  );

  const [issue_date_from_url, set_issue_date_from_url] = useState(
    title === "Documents"
      ? query?.issue_date ?? null
      : query?.media_issue_date ?? null
  );

  const [renewal_date_from_url, set_renewal_date_from_url] = useState(
    title === "Documents"
      ? query?.renewal_date ?? null
      : query?.media_renewal_date ?? null
  );

  const [expiration_date_from_url, set_expiration_date_from_url] = useState(
    title === "Documents"
      ? query?.expiration_date ?? null
      : query?.media_expiration_date ?? null
  );

  function getObjArrayFromStringArray(strArr: string[]) {
    return strArr?.map((name) => ({ name }));
  }

  const { data: assetLanguages } = useSWR<{
    languages: Language[];
  }>(endpoints.v1_storefronts_id_languages(storefront_id), {
    onError: () => {
      notifyError(t("could not load asset languages, something went wrong."));
    },
  });

  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Name"),
        accessorKey: "name",
        cell: ({
          getValue,
          row: {
            original: {
              asset_type,
              visibility,
              is_generated,
              content_type,
              name,
            },
          },
        }: {
          getValue: Getter<string>;
          row: {
            original: Assets;
          };
        }) => (
          <AssetNameIcon
            asset_type={asset_type}
            signed_url={`https://${window.location.hostname}/api/store/${slug}/products/${product.slug}/${asset_type}/${name}`}
            name={getValue()}
            is_downloadable={
              visibility === "public" ||
              (!roleIsGuest && visibility === "logged_in_user") ||
              roleIsSomeKindOfSeller
            }
            is_generated={is_generated}
            content_type={content_type}
          />
        ),
      },
      {
        header: t("Category"),
        accessorKey: "asset_category",
        cell: ({
          getValue,
          row: {
            original: { visibility },
          },
        }: {
          getValue: Getter<string>;
          row: { original: Assets };
        }) => (
          <DocPortfolioName
            disabled={isOutsideLogin && visibility !== "public"}
          >
            {getValue()}
          </DocPortfolioName>
        ),
      },
      {
        header: t("Language"),
        accessorKey: "language",
        cell: ({
          row: {
            original: { language, visibility },
          },
        }: {
          row: { original: Assets };
        }) => (
          <DocPortfolioName
            disabled={isOutsideLogin && visibility !== "public"}
          >
            {language?.toLocaleUpperCase()}
          </DocPortfolioName>
        ),
      },
      ...(roleIsSellerAdmin &&
      (tableData.find((asset) => asset.country !== "--") ||
        tableData.find((asset) => asset.region !== "--"))
        ? [
            {
              header: t("Country / Region"),
              accessorKey: "country_region",
              enableSorting: false,
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.issue_date !== "--")
        ? [
            {
              header: t("Issue Date"),
              accessorKey: "issue_date",
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.expiration_date !== "--")
        ? [
            {
              header: t("Expiration Date"),
              accessorKey: "expiration_date",
            },
          ]
        : []),
      ...(roleIsSellerAdmin &&
      tableData.find((asset) => asset.document_type !== "--")
        ? [
            {
              header: t("Document Type"),
              accessorKey: "document_type",
            },
          ]
        : []),
      ...(roleIsSellerAdmin
        ? [
            {
              header: "",
              accessorKey: "info",
              enableSorting: false,
              align: "right",
              width: "40px",
              cell: ({
                row,
                getValue,
              }: {
                getValue: Getter<{
                  modified_at: string;
                  modified_by: string;
                  number_of_products: string;
                  renewal_date: string;
                }>;
                row: RowType<Assets>;
              }) => {
                return (
                  <div
                    data-for={`row-tooltip-${row.original.id}`}
                    data-tip={<></>}
                    style={{ display: "inline" }}
                  >
                    <InfoIcon width={20} height={20} />
                    <ReactTooltip
                      id={`row-tooltip-${row.original.id}`}
                      place="top"
                      data-html={true}
                      effect="solid"
                      multiline={true}
                    >
                      {
                        <div style={{ textAlign: "left" }}>
                          <span
                            style={{ marginBottom: "3px", display: "block" }}
                          >
                            {t("No. of Products")}:{" "}
                            {getValue().number_of_products}
                          </span>
                          {getValue().renewal_date !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Renewal Date")}: {getValue().renewal_date}
                            </span>
                          )}
                          {getValue().modified_at !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Last Modified On")}: {getValue().modified_at}
                            </span>
                          )}
                          {getValue().modified_by !== "--" && (
                            <span
                              style={{ marginBottom: "3px", display: "block" }}
                            >
                              {t("Last Modified By")}: {getValue().modified_by}
                            </span>
                          )}
                        </div>
                      }
                    </ReactTooltip>
                  </div>
                );
              },
            },
          ]
        : []),
      ...(isOutsideLogin
        ? []
        : [
            {
              header: productStatus !== "archived" ? t("Actions") : "",
              accessorKey: "visibility",
              cell: ({
                getValue,
                row: { original, index },
              }: {
                getValue: Getter<SupportedAssetAccessibility>;
                row: { original: Assets; index: number };
              }) =>
                productStatus !== "archived" && (
                  <IconWrapper>
                    <BtnWrapper
                      data-for={`row-link-${original.id}`}
                      data-tip={t("Copy asset link to clipboard")}
                      onClick={() =>
                        navigator.clipboard
                          .writeText(
                            `https://${window.location.hostname}/api/store/${slug}/products/${product.slug}/${original.asset_type}/${original.name}`
                          )
                          .then(
                            function () {
                              notifySuccess(
                                t("Asset link copied to clipboard")
                              );
                            },
                            function (err) {
                              notifyError("something went wrong.", err);
                            }
                          )
                      }
                      disabled={is_publishing_product}
                    >
                      <LinkIcon
                        fill={
                          is_publishing_product
                            ? theme.disabledButtonBG
                            : theme.primaryIconColor
                        }
                        width={20}
                        height={20}
                      />
                      <ReactTooltip
                        id={`row-link-${original.id}`}
                        effect="solid"
                      />
                    </BtnWrapper>
                    {product.is_editable &&
                      assets?.data &&
                      assets?.data.length > 0 && (
                        <WithPermission permission="modify_assets">
                          <BtnWrapper
                            onClick={() =>
                              assets?.data
                                ? handleEdit(assets?.data[index])
                                : {}
                            }
                            disabled={is_publishing_product}
                          >
                            <EditIcon
                              fill={
                                is_publishing_product
                                  ? theme.disabledButtonBG
                                  : theme.primaryIconColor
                              }
                              width={20}
                              height={20}
                            />
                          </BtnWrapper>
                        </WithPermission>
                      )}
                    {title === "Documents" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          return getValue() === "public" ? (
                            <>
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                                data-for={`unlocked-asset-${original.id}`}
                                data-tip={t("Public")}
                              >
                                <UnLockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                              <ReactTooltip
                                id={`unlocked-asset-${original.id}`}
                                effect="solid"
                              />
                            </>
                          ) : getValue() === "logged_in_user" ? (
                            <>
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                                data-for={`semiLocked-asset-${original.id}`}
                                data-tip={t("Customers & Internal Users")}
                              >
                                <UnLockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : "#f69900"
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                              <ReactTooltip
                                id={`semiLocked-asset-${original.id}`}
                                effect="solid"
                              />
                            </>
                          ) : (
                            <>
                              <BtnWrapper
                                disabled={is_publishing_product}
                                onClick={() => handleChangeVisibility(original)}
                                data-for={`locked-asset-${original.id}`}
                                data-tip={t("Internal Users Only")}
                              >
                                <LockIcon
                                  fill={
                                    is_publishing_product
                                      ? theme.disabledButtonBG
                                      : undefined
                                  }
                                  width={20}
                                  height={20}
                                />
                              </BtnWrapper>
                              <ReactTooltip
                                id={`locked-asset-${original.id}`}
                                effect="solid"
                              />
                            </>
                          );
                        } else {
                          return null;
                        }
                      })()}
                    {title === "Digital Media" &&
                      (() => {
                        if (
                          hasPermission("modify_assets") &&
                          product.is_editable
                        ) {
                          if (getValue() === "public") {
                            return (
                              <div
                                data-tip={
                                  original.is_cover_image
                                    ? product?.cover_image?.id === original.id
                                      ? t(
                                          "You cannot hide the product cover image"
                                        )
                                      : t(
                                          "This asset is used as a cover image for another product."
                                        )
                                    : t("Public")
                                }
                                data-for={`disabled-cover-image`}
                              >
                                <BtnWrapper
                                  disabled={original.is_cover_image}
                                  onClick={() =>
                                    handleChangeVisibility(original)
                                  }
                                >
                                  <VisibleIcon
                                    width={20}
                                    height={20}
                                    fill={
                                      original.is_cover_image
                                        ? theme.disabledLinkColor
                                        : theme.successIconColor
                                    }
                                  />
                                </BtnWrapper>
                                <ReactTooltip id={`disabled-cover-image`} />
                                <ReactTooltip
                                  id={`locked-asset-${original.id}`}
                                />
                              </div>
                            );
                          } else if (getValue() === "logged_in_user") {
                            return (
                              <div
                                data-tip={
                                  original.is_cover_image
                                    ? product?.cover_image?.id === original.id
                                      ? t(
                                          "You cannot hide the product cover image"
                                        )
                                      : t(
                                          "This asset is used as a cover image for another product."
                                        )
                                    : t("Customers & Internal Users")
                                }
                                data-for={`disabled-cover-image`}
                              >
                                <BtnWrapper
                                  disabled={original.is_cover_image}
                                  onClick={() =>
                                    handleChangeVisibility(original)
                                  }
                                >
                                  <VisibleIcon
                                    width={20}
                                    height={20}
                                    fill={
                                      original.is_cover_image
                                        ? theme.disabledLinkColor
                                        : "#f69900"
                                    }
                                  />
                                </BtnWrapper>
                                <ReactTooltip id={`disabled-cover-image`} />
                              </div>
                            );
                          } else {
                            return (
                              <>
                                <BtnWrapper
                                  onClick={() =>
                                    handleChangeVisibility(original)
                                  }
                                  data-for={`locked-asset-${original.id}`}
                                  data-tip={t("Internal Users Only")}
                                >
                                  <NonVisibleIcon
                                    width={20}
                                    height={20}
                                    fill={theme.errorColor}
                                  />
                                </BtnWrapper>
                                <ReactTooltip
                                  id={`locked-asset-${original.id}`}
                                />
                              </>
                            );
                          }
                        } else return null;
                      })()}
                    {product.is_editable && (
                      <WithPermission permission="delete_assets">
                        <BtnWrapper
                          disabled={is_publishing_product}
                          onClick={() => handleDelete(original)}
                        >
                          <DeleteIcon
                            fill={
                              is_publishing_product
                                ? theme.disabledButtonBG
                                : theme.errorColor
                            }
                          />
                        </BtnWrapper>
                      </WithPermission>
                    )}
                  </IconWrapper>
                ),
            },
          ]),
    ],
    [
      t,
      isOutsideLogin,
      productStatus,
      title,
      theme.errorColor,
      theme.disabledLinkColor,
      theme.successIconColor,
      hasPermission,
      product,
      is_publishing_product,
      theme.disabledButtonBG,
      theme.primaryIconColor,
      tableData,
      assets,
      roleIsSellerAdmin,
      roleIsGuest,
      roleIsSomeKindOfSeller,
      notifyError,
      notifySuccess,
      slug,
    ]
  );

  const handleChangeVisibility = (original: Assets) => {
    setShowEditPopup(true);
    setEditRowVisibility(original.visibility);
    setEditRowData(original);
  };

  const onComplete = async (productId?: string, shouldFetchData = false) => {
    if (productId) {
      replaceProductId(productId);
    } else {
      if (shouldFetchData) {
        await fetchProductData();
        mutateAssetFilters();
        mutateAssets();
      }
    }
    closeSlideOut();
  };

  const handleDelete = (original: Assets) => {
    setShowDeleteModal(true);
    setDeleteRowData(original);
  };

  const handleEdit = (original: Assets) => {
    setSlideOut("edit");
    setEditRowData(original);
  };

  const handleEditConfirm = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      const assetId = editRowData ? editRowData.id : "";
      const baseURL = endpoints.v2_storefronts_id_pim_assets_id(
        tenant_id,
        assetId
      );

      await Axios.patch(baseURL, { visibility: editRowVisibility });
      await fetchProductData();
      await mutateAssets();
      mutateAssetFilters();
      notifySuccess(t("Asset successfully Edited"));
      setShowEditPopup(false);
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not edit asset, Something went wrong."));
      }
    } finally {
      setIsSubmitting(false);
      setEditRowVisibility(null);
      setShowEditConfirm(false);
    }
  };

  const handleClearFilters = () => {
    setQuery(
      title === "Documents"
        ? {
            document_category: undefined,
            document_type: undefined,
            document_country: undefined,
            document_region: undefined,
            document_language: undefined,
            asset_type: undefined,
            issue_date: undefined,
            expiration_date: undefined,
            renewal_date: undefined,
          }
        : {
            media_category: undefined,
            media_document_type: undefined,
            asset_type: undefined,
            media_country: undefined,
            media_region: undefined,
            media_language: undefined,
            media_issue_date: undefined,
            media_expiration_date: undefined,
            media_renewal_date: undefined,
          },
      "replaceIn"
    );
  };

  useEffect(() => {
    const handleProductsData = (assets: Assets[]) => {
      setTableData(
        assets.reduce<TableData>(
          (
            acc,
            {
              id,
              name,
              asset_category,
              category,
              modified_at,
              modified_by,
              is_generated,
              asset_type,
              signed_url,
              language,
              number_of_products,
              issue_date,
              expiration_date,
              renewal_date,
              country,
              region,
              is_cover_image,
              document_type,
              content_type,
              visibility,
              ...rest
            }
          ) => {
            acc.push({
              id,
              asset_category,
              category,
              name: name ?? "--",
              modified_at: formatDate(modified_at),
              modified_by: modified_by ?? "--",
              visibility,
              is_generated,
              asset_type,
              document_type: document_type ?? "--",
              signed_url,
              language: language?.toLocaleUpperCase(),
              issue_date: issue_date ? formatDate(issue_date, true) : "--",
              expiration_date: expiration_date
                ? formatDate(expiration_date, true)
                : "--",
              renewal_date: renewal_date
                ? formatDate(renewal_date, true)
                : "--",
              country: country ?? "--",
              country_region: `${
                getCountryOption(countries, country)?.label ?? "--"
              } / ${region ?? "--"}`,
              region: region ?? "--",
              number_of_products: number_of_products,
              info: {
                modified_at: formatDate(modified_at),
                modified_by: modified_by ?? "--",
                number_of_products: number_of_products,
                renewal_date: renewal_date
                  ? formatDate(renewal_date, true)
                  : "--",
              },
              is_cover_image,
              content_type: content_type ?? "application/pdf",
              canSelect:
                visibility === "public" ||
                (!roleIsGuest && visibility === "logged_in_user") ||
                roleIsSomeKindOfSeller,
              rowTooltipMessage:
                roleIsGuest && visibility !== "public"
                  ? match(asset_type)
                      .with("image", () => t("Register to access this image"))
                      .with("document", () =>
                        t("Register to access this document")
                      )
                      .with("video", () => t("Register to access this video"))
                      .otherwise(() => <DocumentIcon />)
                  : undefined,
              ...rest,
            });
            return acc;
          },
          []
        )
      );
    };
    if (assetData) {
      handleProductsData(assetData);
    }
  }, [
    assetData,
    countries,
    categoryTypesFromURL,
    languagesFromURL,
    countriesFromURL,
    mediaTypesFromURL,
    regionsFromURL,
    docTypesFromURL,
    roleIsGuest,
    roleIsSomeKindOfSeller,
    t,
  ]);

  useEffect(() => {
    if (assetFilters) {
      setCategoryTypes(assetFilters.asset_category);

      setAssetTypes(assetFilters.asset_type);

      setRegions(assetFilters.region);
      setCountryFilters(
        assetFilters.country
          .map((country) =>
            country ? getCountryOption(countries, country)?.label || "" : ""
          )
          .filter((val) => val)
      );

      setRegions(
        assetFilters.region
          .map((region) => region)
          .filter((val) => val !== "--")
      );
      setDocumentTypesFilters(
        assetFilters.document_type
          .map((document_type) => document_type)
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, countries]);

  useEffect(() => {
    if (assetLanguages && assetFilters) {
      setLanguagesFilters(
        assetFilters?.language
          .map(
            (language) =>
              assetLanguages?.languages.find(
                (lang) => lang.alpha_2 === language?.toLowerCase()
              )?.name ?? "--"
          )
          .filter((val) => val !== "--")
      );
    }
  }, [assetFilters, assetLanguages]);

  useEffect(() => {
    setCategoryTypesFromURL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_category
          ? query?.document_category
          : title === "Digital Media" && query?.media_category
          ? query?.media_category
          : []) as string[]
      )
    );

    setMediaTypesFromURL(
      getObjArrayFromStringArray((query?.asset_type ?? []) as string[])
    );

    setLanguagesFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_language
          ? query?.document_language
          : title === "Digital Media" && query?.media_language
          ? query?.media_language
          : []) as string[]
      )
    );
    setCountriesFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_country
          ? query?.document_country?.map(
              (country: string) =>
                countries.find((c) => c.value === country)?.label
            )
          : title === "Digital Media" && query?.media_country
          ? query?.media_country?.map(
              (country: string) =>
                countries.find((c) => c.value === country)?.label
            )
          : []
        )?.filter((t: string) => t !== undefined) as string[]
      )
    );
    setDocTypesFromURL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_type
          ? query?.document_type
          : title === "Digital Media" && query?.media_document_type
          ? query?.media_document_type
          : []) as string[]
      )
    );
    setRegionsFromURLL(
      getObjArrayFromStringArray(
        (title === "Documents" && query?.document_region
          ? query?.document_region
          : title === "Digital Media" && query?.media_region
          ? query?.media_region
          : []) as string[]
      )
    );
    set_issue_date_from_url(
      title === "Documents"
        ? query.issue_date ?? null
        : query.media_issue_date ?? null
    );
    set_expiration_date_from_url(
      title === "Documents"
        ? query.expiration_date ?? null
        : query.media_expiration_date ?? null
    );
    set_renewal_date_from_url(
      title === "Documents"
        ? query.renewal_date ?? null
        : query.media_renewal_date ?? null
    );
  }, [query, title, countries]);

  // Only tds document generation is supported for now
  const hasActiveGeneratedAssetConfig =
    // despite being called `generated_assets`, this is actually a config object.
    product.product_schema.generated_assets.some(
      (genAsset) => genAsset.is_active
    );

  const canGenerateNewTDS = product.number_of_generated_assets === 0;

  const handleCategoryType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    setQuery(
      title === "Documents"
        ? { document_category: chipsToStringArray(values) }
        : { media_category: chipsToStringArray(values) },
      "replaceIn"
    );
    setActiveFilter("asset_category");
  };

  const handleMediaType = ({
    values,
  }: {
    values: ChipType[];
    filter: string;
  }) => {
    setQuery({ asset_type: chipsToStringArray(values) }, "replaceIn");
    setActiveFilter("asset_type");
  };

  const handleCountryFilter = ({ values }: { values: ChipType[] }) => {
    let countryList = chipsToStringArray(values).map(
      (country) => countries.find((c) => c.label === country)?.value ?? country
    );
    setQuery(
      title === "Documents"
        ? {
            document_country: countryList,
          }
        : {
            media_country: countryList,
          },
      "replaceIn"
    );
    setActiveFilter("country");
  };

  const handleRegionFilter = ({ values }: { values: ChipType[] }) => {
    let regions = chipsToStringArray(values);
    setQuery(
      title === "Documents"
        ? { document_region: regions }
        : { media_region: regions },
      "replaceIn"
    );
    setActiveFilter("region");
  };

  const handleLanguageFilter = ({ values }: { values: ChipType[] }) => {
    let languages = chipsToStringArray(
      values.map((val) => ({
        name:
          assetLanguages?.languages.find(
            (languages) => languages.name.indexOf(val.name) === 0
          )?.alpha_2 || val.name,
      }))
    );
    setQuery(
      title === "Documents"
        ? { document_language: languages }
        : { media_language: languages },
      "replaceIn"
    );
    setActiveFilter("language");
  };

  const handleDocTypeFilter = ({ values }: { values: ChipType[] }) => {
    let docTypes = chipsToStringArray(values);
    setQuery(
      title === "Documents"
        ? { document_type: docTypes }
        : { media_document_type: docTypes },
      "replaceIn"
    );
    setActiveFilter("document_type");
  };

  const handleIssueDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setQuery(
      title === "Documents"
        ? { issue_date: updated_values }
        : { media_issue_date: updated_values },
      "replaceIn"
    );
    setActiveFilter("issue_date");
  };

  const handleExpirationDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setQuery(
      title === "Documents"
        ? { expiration_date: updated_values }
        : { media_expiration_date: updated_values },
      "replaceIn"
    );
    setActiveFilter("expiration_date");
  };

  const handleRenewalDateFilter = ({ values }: { values: DateRange }) => {
    const updated_values = {
      startDate: values?.startDate?.toISOString(),
      endDate: values?.endDate?.endOf("day").toISOString(),
    };
    setQuery(
      title === "Documents"
        ? { renewal_date: updated_values }
        : { media_renewal_date: updated_values },
      "replaceIn"
    );
    setActiveFilter("renewal_date");
  };

  useEffect(() => {
    if (assets?.data) {
      if (offset === 0) {
        setAssetData(assets?.data);
      } else {
        setAssetData((prev) => [...prev, ...assets?.data]);
      }
      setPagination(assets.pagination);
      setIsLoadingData(false);
    }
  }, [assets, offset]);

  useEffect(() => {
    setOffset(0);
  }, [query]);

  const onScroll = () => {
    if (scrollRef.current) {
      const scrollTop = scrollRef.current.scrollTop;
      if (
        scrollRef.current.offsetHeight - scrollTop < 400 &&
        !isLoadingData &&
        ((pagination?.total && assetData.length < pagination?.total) ||
          !pagination) &&
        !isValidatingAssets
      ) {
        setIsLoadingData(true);
        setOffset(offset + requestLimit);
      }
    }
  };

  useEffect(() => {
    if (selectedRows && selectedRows !== "all" && assetData.length) {
      const selected_rows_obj = selectedRows.reduce<Record<string, boolean>>(
        (acc, id) => ({ ...acc, [id]: true }),
        {}
      );
      setSelectedItems(
        !selectedRows.length
          ? []
          : assetData.filter((asset) => selected_rows_obj[asset.id])
      );
    }
  }, [assetData, selectedRows]);

  return (
    <>
      <Row>
        <div>
          <H3 style={{ marginBottom: "4px" }}>
            {title === "Documents" ? t("Documents") : t("Digital Media")}
          </H3>
          <SmallText style={{ marginBottom: "16px" }}>
            {t(`Manage {{title}} and control their accessibility`, { title })}
          </SmallText>
        </div>
        <div style={{ alignSelf: "flex-start" }}>
          <Row>
            {(isValidatingAssetFilters || isValidatingAssets) && (
              <div style={{ marginRight: "20px", marginTop: "23px" }}>
                <LoadingIcon width={22} height={22} />
              </div>
            )}
            <ListGridToggleButton
              set_toggle_state={set_list_grid_toggle}
              name={`${title}${
                isOutsideLogin ? "_portfolio" : ""
              }_list_grid_toggle`}
            />{" "}
          </Row>
        </div>
      </Row>
      {assetTypes?.length > 0 && roleIsSomeKindOfSeller && (
        <TableFiltersWrapper>
          {title === "Digital Media" && assetTypes && (
            <FilterBy
              setter={handleMediaType}
              filter={"asset_type"}
              options={getObjArrayFromStringArray(assetTypes)}
              header={t("Type")}
              label={t("Type")}
              preselectedFilters={
                isChipTypeArray(mediaTypesFromURL) ? mediaTypesFromURL : []
              }
            />
          )}
          {categoryTypes && (
            <FilterBy
              setter={handleCategoryType}
              filter={
                title === "Documents" ? "document_category" : "media_category"
              }
              options={getObjArrayFromStringArray(categoryTypes)}
              header={t("Category")}
              label={t("Category")}
              preselectedFilters={
                isChipTypeArray(categoryTypesFromURL)
                  ? categoryTypesFromURL
                  : []
              }
            />
          )}
          {assetLanguages && languagesFilters.length > 0 && (
            <FilterBy
              setter={handleLanguageFilter}
              filter="language"
              options={getObjArrayFromStringArray(languagesFilters)}
              header={t("Language")}
              label={t("Language")}
              preselectedFilters={
                isChipTypeArray(languagesFromURL)
                  ? languagesFromURL.map((lang) => ({
                      name:
                        assetLanguages.languages.find(
                          (assetLang) => assetLang.alpha_2 === lang.name
                        )?.name ?? lang.name,
                    }))
                  : []
              }
            />
          )}
          {countryFilters.length > 0 && (
            <FilterBy
              setter={handleCountryFilter}
              filter="country"
              options={getObjArrayFromStringArray(countryFilters)}
              header={t("Country")}
              label={t("Country")}
              preselectedFilters={
                isChipTypeArray(countriesFromURL) ? countriesFromURL : []
              }
            />
          )}
          {regions?.length > 0 && (
            <FilterBy
              setter={handleRegionFilter}
              filter="region"
              options={regions.map((region) => ({ name: region }))}
              header={t("Region")}
              label={t("Region")}
              preselectedFilters={
                isChipTypeArray(regionsFromURL) ? regionsFromURL : []
              }
            />
          )}

          {documentTypesFilters?.length > 0 && (
            <FilterBy
              setter={handleDocTypeFilter}
              filter="document_type"
              options={getObjArrayFromStringArray(documentTypesFilters)}
              header={t("Document Type")}
              label={t("Document Type")}
              preselectedFilters={
                isChipTypeArray(docTypesFromURL) ? docTypesFromURL : []
              }
            />
          )}
          <DateFilter
            setter={handleIssueDateFilter}
            filter="issue_date"
            label={t("Issue Date")}
            preselectedDateRange={get_preselected_date_range(
              issue_date_from_url as DateRangeStr
            )}
          />
          <DateFilter
            setter={handleExpirationDateFilter}
            filter="expiration_date"
            label={t("Expiration Date")}
            preselectedDateRange={get_preselected_date_range(
              expiration_date_from_url as DateRangeStr
            )}
          />
          <DateFilter
            setter={handleRenewalDateFilter}
            filter="renewal_date"
            label={t("Renewal Date")}
            preselectedDateRange={get_preselected_date_range(
              renewal_date_from_url as DateRangeStr
            )}
          />
          {assetQueryAsParam(query).length > 0 && (
            <TertiaryTextButtonSmall
              onClick={() => handleClearFilters()}
              style={{ marginBottom: 0 }}
            >
              {t("Clear Filters")}
            </TertiaryTextButtonSmall>
          )}
        </TableFiltersWrapper>
      )}
      <TableContainer>
        {list_grid_toggle === "list" ? (
          <>
            {isShowTableWithBulkAction ? (
              <TableWithBulkAction
                columns={tableColumns}
                data={tableData}
                isLoading={isLoadingData}
                error={assetsError}
                lastChildleftAlign
                Placeholder={<TablePlaceholder />}
                handleSelectRows={(rows) => setSelectedRows(rows)}
                defaultSelectedRows={selectedRows}
                bulkActionElement={({ rows, resetSelectedRows }) => {
                  return (
                    <BulkActionOnAssets
                      rows={rows}
                      resetSelectedRows={resetSelectedRows}
                      tenant_id={tenant_id}
                      fetchAssetsForDownload={fetchAssetsForDownload}
                    />
                  );
                }}
                onScroll={onScroll}
                scrollRef={scrollRef}
                bulkSelectLimit={500}
              />
            ) : (
              <ScrollTableWrapper ref={scrollRef} onScroll={onScroll}>
                <Table
                  columns={tableColumns}
                  data={tableData}
                  isLoading={isLoadingData}
                  error={assetsError}
                  lastChildleftAlign
                  Placeholder={<TablePlaceholder />}
                />
              </ScrollTableWrapper>
            )}
          </>
        ) : (
          <ScrollGridWrapper ref={scrollRef} onScroll={onScroll}>
            {isShowGridAssetWithBulkAction ? (
              <GridAssetViewWithBulkAction
                assets={
                  assetData.map((asset) => ({
                    ...asset,
                    can_edit:
                      !isOutsideLogin &&
                      hasPermission("modify_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_change_visibility:
                      !isOutsideLogin &&
                      hasPermission("modify_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_delete:
                      !isOutsideLogin &&
                      hasPermission("delete_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_external_download:
                      asset.visibility === "public" ||
                      (!roleIsGuest && asset.visibility === "logged_in_user") ||
                      roleIsSomeKindOfSeller,
                    can_view_details: !isOutsideLogin,
                  })) as unknown as (Assets & GridItemProps)[]
                }
                handle_asset_visibility_change={handleChangeVisibility}
                on_edit_asset={handleEdit}
                on_remove_asset={handleDelete}
                on_download_asset={(asset) => {
                  window.open(
                    `https://${window.location.hostname}/api/store/${slug}/products/${product.slug}/${asset.asset_type}/${asset.name}`,
                    "_blank"
                  );
                }}
                handleSelectedItems={(items) =>
                  setSelectedRows(items.map((item) => item.original.id))
                }
                selectedItems={selectedItems.map(
                  (item) =>
                    ({ original: item, id: `${item.id}` } as TableRow<Assets>)
                )}
                bulkActionElement={({ rows, resetSelectedRows }) => {
                  return (
                    <BulkActionOnAssets
                      rows={rows}
                      resetSelectedRows={resetSelectedRows}
                      tenant_id={tenant_id}
                      fetchAssetsForDownload={fetchAssetsForDownload}
                    />
                  );
                }}
                bulkSelectLimit={500}
              />
            ) : (
              <GridAssetView
                assets={
                  assetData.map((asset) => ({
                    ...asset,
                    can_edit:
                      !isOutsideLogin &&
                      hasPermission("modify_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_change_visibility:
                      !isOutsideLogin &&
                      hasPermission("modify_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_delete:
                      !isOutsideLogin &&
                      hasPermission("delete_assets") &&
                      productStatus !== "archived" &&
                      product.is_editable,
                    can_external_download:
                      asset.visibility === "public" ||
                      (!roleIsGuest && asset.visibility === "logged_in_user") ||
                      roleIsSomeKindOfSeller,
                    can_view_details: !isOutsideLogin,
                  })) as unknown as (Assets & GridItemProps)[]
                }
                handle_asset_visibility_change={handleChangeVisibility}
                on_edit_asset={handleEdit}
                on_remove_asset={handleDelete}
                productSlug={product.slug}
                on_download_asset={(asset) => {
                  window.open(
                    `https://${window.location.hostname}/api/store/${slug}/products/${product.slug}/${asset.asset_type}/${asset.name}`,
                    "_blank"
                  );
                }}
              />
            )}
          </ScrollGridWrapper>
        )}
      </TableContainer>

      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeSlideOut} show={!!showSlideOut}>
          <AddAssetsForm
            title={title}
            onComplete={onComplete}
            product={product}
            fetchProductData={mutateAssets}
          />
        </SlideOut>
      )}

      {showSlideOut === "edit" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await fetchProductData();
            await mutateAssets();
            mutateAssetFilters();
          }}
          show={!!showSlideOut}
        >
          {editRowData?.is_generated ? (
            <EditOrGenerateProductDocument
              asset={{
                ...editRowData,
                language: getLanguageOptionFromSupportedLanguage(
                  editRowData!.language,
                  supported_languages
                ).value,
              }}
              ref={product_document_ref}
              onSuccess={onComplete}
              product={product}
            />
          ) : (
            <EditAssetForm
              onComplete={onComplete}
              fetchProductData={mutateAssets}
              product={product}
              assetData={editRowData}
              formType={title}
            />
          )}
        </SlideOut>
      )}
      {showSlideOut === "generate" && (
        <SlideOut
          closeFlyout={async () => {
            closeSlideOut();
            product_document_ref.current?.clear_poll_timeout();
            await mutateAssets();
            mutateAssetFilters();
          }}
          show={!!showSlideOut}
        >
          <EditOrGenerateProductDocument
            product={product}
            ref={product_document_ref}
            replaceProductId={replaceProductId}
            onSuccess={onComplete}
          />
        </SlideOut>
      )}
      {showEditPopup && (
        <>
          <Modal
            overlay
            show={showEditPopup}
            closeModal={() => {
              setShowEditPopup(false);
              setShowEditConfirm(false);
            }}
          >
            <H3 style={{ margin: "10px 0px" }}>
              {t("Edit Asset Accessibility")}
            </H3>
            {!showEditConfirm && (
              <>
                <div style={{ marginBottom: "30px" }}>
                  <SmallText>
                    {editRowData?.asset_type === "document"
                      ? t("Document")
                      : t("Media")}{" "}
                    {t("can be downloaded by:")}
                  </SmallText>
                  <div style={{ marginBottom: "15px", marginTop: "20px" }}>
                    <RadioButton
                      name={"public"}
                      value="public"
                      checked={editRowVisibility === "public"}
                      optionTitle={t("Public")}
                      handleChange={() => setEditRowVisibility("public")}
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <RadioButton
                      name={"logged_in_user"}
                      value="logged_in_user"
                      checked={editRowVisibility === "logged_in_user"}
                      optionTitle={t("Customers & Internal Users")}
                      handleChange={() =>
                        setEditRowVisibility("logged_in_user")
                      }
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <RadioButton
                      name={"internal"}
                      value="internal"
                      checked={editRowVisibility === "internal"}
                      optionTitle={t("Internal Users Only")}
                      handleChange={() => setEditRowVisibility("internal")}
                    />
                  </div>
                </div>
                <PrimaryCancelButton
                  loading={isSubmitting}
                  style={{ width: "100%" }}
                  onClick={() => setShowEditConfirm(true)}
                >
                  {t("Save Changes")}
                </PrimaryCancelButton>
              </>
            )}
            {showEditConfirm && (
              <>
                <ModalWrapper>
                  <div style={{ textAlign: "center" }}>
                    {editRowData?.visibility === "public" &&
                    editRowData.number_of_attribute_values > 0
                      ? t(
                          "This asset will become inaccessible to users outside login. Would you like to continue?"
                        )
                      : t(
                          " This change will affect {{number_of_products}} other products. Would you like to continue?",
                          {
                            number_of_products: editRowData?.number_of_products,
                          }
                        )}
                  </div>
                </ModalWrapper>
                <Flex
                  style={{
                    columnGap: "16px",
                  }}
                >
                  <SecondaryButtonMedium
                    style={{ width: "100%" }}
                    onClick={() => {
                      setShowEditPopup(false);
                      setShowEditConfirm(false);
                    }}
                  >
                    {t("Cancel")}
                  </SecondaryButtonMedium>
                  <PrimaryCancelButton
                    loading={isSubmitting}
                    style={{ width: "100%" }}
                    onClick={handleEditConfirm}
                  >
                    {t("Confirm")}
                  </PrimaryCancelButton>
                </Flex>
              </>
            )}
          </Modal>
        </>
      )}

      {showDeleteModal && (
        <RemoveAssetFromProduct
          onComplete={(id) => {
            onComplete(id);
            closeDeleteModal();
          }}
          product={product}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={mutateAssets}
          assetData={deleteRowData}
        />
      )}

      {hasPermission("modify_assets") &&
        productStatus !== "archived" &&
        product.is_editable && (
          <div style={{ display: "flex", gap: "16px" }}>
            <PrimaryButtonWithPlusIcon
              style={{ width: "fit-content" }}
              onClick={() => setSlideOut("add")}
            >
              {t("Add")}
            </PrimaryButtonWithPlusIcon>
            {hasActiveGeneratedAssetConfig &&
              title === "Documents" &&
              canGenerateNewTDS && (
                <PrimaryButtonWithPlusIcon
                  style={{ width: "fit-content" }}
                  onClick={() => setSlideOut("generate")}
                >
                  {t("Generate Document")}
                </PrimaryButtonWithPlusIcon>
              )}
          </div>
        )}
    </>
  );
};
