import type { Row } from "@tanstack/react-table";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextButton } from "../../../../../../components/Buttons/Buttons";
import { TableWrapper } from "../../../../../../components/Table/Table";
import type { TableRefProps } from "../../../../../../components/Table/utils";
import { SoftHeader2 } from "../../../../../../components/Typography/Typography";
import type { Assets, GridItemProps } from "../../../../../../types/types.PIM";
import type { GridAssetsSelectProps } from "./GridAssetView";
import { GridAssetView } from "./GridAssetView";

const BulkActionWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-right: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
`;

const ResetBtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export function GridAssetViewWithBulkAction({
  assets,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  bulkActionElement,
  bulkSelectLimit,
  selectedItems,
  handleSelectedItems,
}: {
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  bulkActionElement: <
    ElementProps extends {
      rows: string[] | "all";
      resetSelectedRows: () => void;
    }
  >(
    props: ElementProps
  ) => JSX.Element;
  bulkSelectLimit: number;
} & GridAssetsSelectProps) {
  const [rowsSelected, setRowsSelected] = useState<string[] | "all">([]);
  const gridAssetViewRef = useRef<TableRefProps>(null);

  const { t } = useTranslation();

  const handleSelectRows = (items: Row<Assets>[]) => {
    setRowsSelected(items.map((item) => item.original.id));
    handleSelectedItems?.(items);
  };

  const handleUnselectAll = () => {
    setRowsSelected([]);
    handleSelectedItems?.([]);
    gridAssetViewRef.current?.resetSelectedRows();
  };

  return (
    <TableWrapper>
      {rowsSelected?.length > 0 ? (
        <BulkActionWrapper>
          <ResetBtnWrapper>
            <SoftHeader2>
              {rowsSelected === "all"
                ? t("All items selected")
                : `${rowsSelected.length} ${t("selected")}`}
            </SoftHeader2>
            <TextButton onClick={handleUnselectAll}>
              <SoftHeader2>{t("Unselect All")}</SoftHeader2>
            </TextButton>
          </ResetBtnWrapper>
          {bulkActionElement({
            rows: rowsSelected,
            resetSelectedRows: handleUnselectAll,
          })}
        </BulkActionWrapper>
      ) : (
        <></>
      )}
      <GridAssetView
        assets={assets}
        handle_asset_visibility_change={handle_asset_visibility_change}
        on_edit_asset={on_edit_asset}
        on_remove_asset={on_remove_asset}
        on_download_asset={on_download_asset}
        handleSelectedItems={handleSelectRows}
        show_download_checkbox={true}
        bulkSelectLimit={bulkSelectLimit}
        selectedItems={selectedItems}
        ref={gridAssetViewRef}
      />
    </TableWrapper>
  );
}
