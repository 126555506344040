import { useState } from "react";
import ReactTooltip from "react-tooltip";
import useSWR from "swr";
import { useNotifications } from "../components/Notifications/NotificationsContext";
import type { LeadAssignee, OptionType } from "../types/types";
import { getCountryOption } from "./location";
import { useStoreState } from "./util";

type BusinessUnitAPIResponse = {
  business_units: string[];
};

/**
 * Custom hook that fetches and returns business units as dropdown options.
 *
 * Uses SWR for data fetching with automatic retry on error and refresh until data is loaded.
 * Transforms the API response into an array of options with value and label properties.
 *
 * @returns {OptionType<string>[]} Array of business unit options in {value, label} format
 *
 * @example
 * ```tsx
 * const businessUnitOptions = useBusinessUnits();
 * // Returns: [{value: "unit1", label: "unit1"}, ...]
 * ```
 *
 * @remarks
 * - Automatically retries on error
 * - Polls every second until business units are loaded
 * - Disables revalidation on focus/reconnect
 * - Shows error notification on fetch failure
 */
export const useBusinessUnits = (): OptionType<string>[] => {
  const [businessUnits, setBusinessUnits] = useState<OptionType<string>[]>();
  const { notifyError } = useNotifications();
  const { storefront_id } = useStoreState();
  useSWR<BusinessUnitAPIResponse>(
    `/v2/storefronts/${storefront_id}/business-units`,
    {
      onError: (error) => {
        notifyError("There was an error fetching the business units.", {
          error,
        });
      },
      refreshInterval: (() => {
        if (businessUnits === undefined) return 1000;
        return 0;
      })(),
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
      onSuccess: (data) => {
        setBusinessUnits(
          data.business_units.map((bus_unit) => {
            return { value: bus_unit, label: bus_unit };
          })
        );
      },
    }
  );
  return businessUnits ?? [];
};

/**
 * Retrieves a business unit option from an array of options based on a given business unit string.
 *
 * @param business_units An array of business unit options.
 * @param business_unit The business unit string to search for.
 * @returns The corresponding business unit option if found, otherwise undefined.
 */
export const getBusinessUnitOption = (
  business_units: OptionType[],
  business_unit: string | undefined
): OptionType<string> | undefined => {
  return business_unit
    ? business_units.find((bus_unit) => bus_unit.value === business_unit)
    : undefined;
};

export const LeadAssigneeDetails = ({
  leadAssignee,
  countries,
}: {
  leadAssignee: LeadAssignee;
  countries: OptionType<string>[];
}) => {
  const leadAsigneeName = `${leadAssignee?.firstname} ${leadAssignee?.lastname}`;
  return (
    <>
      <div data-for="lead_assignee_name" data-tip>
        {leadAsigneeName}
      </div>
      <ReactTooltip id="lead_assignee_name" effect="solid" place="top">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
          }}
        >
          <span>{`Email Id: ${leadAssignee.email_address}`}</span>
          {leadAssignee?.business_unit && (
            <span>{`Business Unit: ${leadAssignee.business_unit}`}</span>
          )}
          {leadAssignee?.country && (
            <span>{`Country: ${
              getCountryOption(countries, leadAssignee.country)?.label
            }`}</span>
          )}
        </div>
      </ReactTooltip>
    </>
  );
};
